import React, { createContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const defaultContext = {
  footer: {
    social: undefined,
    news: undefined,
    duo: undefined,
  },
}

export const SiteDetailsContext = createContext(defaultContext)

export default function SiteDetailsProvider({ children }) {
  const showLog = process.env.GATSBY_HOOKS_LOGGER || false

  const { sanitySiteMenu } = useStaticQuery(graphql`
    query {
      sanitySiteMenu {
        showSocialMedia
        showNewsletter
      }
    }
  `)

  // Footer
  const social = sanitySiteMenu?.showSocialMedia
  const news = sanitySiteMenu?.showNewsletter
  const footer = {
    social,
    news,
    duo: social === true && news === true ? true : false,
  }

  if (process.env.NODE_ENV === 'development' && showLog) console.table({ footer })
  return <SiteDetailsContext.Provider value={{ footer }}>{children}</SiteDetailsContext.Provider>
}
