import { createGlobalStyle } from 'styled-components'

// CSS
import './reset.css'
import './font.css'

// Theme
import theme from './index'

const themeDisable = process.env.GATSBY_THEME_LOGGER || false

if (process.env.NODE_ENV === 'development' && themeDisable) {
  console.log('Theme Breakpoints')
  console.table(theme.breakpoints)
  console.log('Theme Fonts')
  console.table(theme.font.mobileSizes)
  console.table(theme.font.fontSizes)
  console.table(theme.font.fontWeight)
  console.table(theme.font.fontFamily)
  console.log('Theme Colors')
  console.table(theme.colors)
  console.log('Theme Spacings')
  console.table(theme.spacings)
  console.log('Theme Transitions')
  console.table(theme.transitions)
}

const GlobalStyles = createGlobalStyle`
html,
body {
   scroll-behavior: smooth;
   min-height: 100vh;
   background: ${theme.colors.color2.default};
   line-height:1.75;
   position: relative;
   display: block;
}
// https://stackoverflow.com/a/66583843/1952287
html {
   overflow: auto;
}
body {
   overflow: hidden;
}

:hover {
   transition: ${theme.transitions.transition1};
}

h1, h2, h3, h4, h5, h6 {
   color: ${({ theme }) => theme.colors.color1.default};
   letter-spacing: 2px;
   text-align: center;
}

h1 {
   font-family: 'Bison Bold';
   font-size: ${({ theme }) => theme.font.fontSizes.h1};
   color: ${({ theme }) => theme.colors.color1.default};
   line-height: 1;

   @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.font.mobileSizes.h1};
   }
}

h2 {
   font-family: 'Bison DemiBold';
   font-size: ${({ theme }) => theme.font.fontSizes.h2};
   color: ${({ theme }) => theme.colors.color1.default};
   line-height: 1.1;

   @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.font.mobileSizes.h2};
      letter-spacing:0;
   }
}

h3 {
   font-family: 'Bison Bold';
   font-size: ${({ theme }) => theme.font.fontSizes.h3};
   color: ${({ theme }) => theme.colors.color1.default};
   line-height: 1;

   @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.font.mobileSizes.h3};
      letter-spacing:0;
   }
}

h4 {
   font-family: ${({ theme }) => theme.font.fontFamily.script};
   font-size: ${({ theme }) => theme.font.fontSizes.h4};
   color: ${({ theme }) => theme.colors.color3.dark.d_1};
   line-height: 1.1;

   @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.font.mobileSizes.h4};
      letter-spacing:0;
   }
}

h5 {
   font-family: 'Bison DemiBold';
   font-size: ${({ theme }) => theme.font.fontSizes.h5};
   color: ${({ theme }) => theme.colors.color1.default};

   @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.font.mobileSizes.h5};
      letter-spacing:0;
   }
}

h6 {
   font-size: ${({ theme }) => theme.font.fontSizes.h6};

   @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: ${({ theme }) => theme.font.mobileSizes.h6};
   }
}

p {
   font-family: ${theme.font.fontFamily.body};
   letter-spacing: 1px;
}
`

export default GlobalStyles
